.keyboard {
    display: flex;
    align-items: center;
    justify-content: center;
}

.keyboard div {
    border: 0.1em solid black;
    width: 2em;
    height: 2em;
    padding: 0.85em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.keyboard .used-char span {
    color: silver;
}

.keyboard .not-used-char span {
    color: black;
}